p{
    font-size: 17px;
}
video{
    max-width: 100%;
    max-height: 100%;
}
.carousel-control 			 { width:  4%; }
.carousel-control.left,.carousel-control.right {margin-left:15px;background-image:none;}
@media (max-width: 767px) {
	.carousel-inner .active.left { left: -100%; }
	.carousel-inner .next        { left:  100%; }
	.carousel-inner .prev		 { left: -100%; }
	.active > div { display:none; }
	.active > div:first-child { display:block; }

}
@media (min-width: 767px) and (max-width: 992px ) {
	.carousel-inner .active.left { left: -50%; }
	.carousel-inner .next        { left:  50%; }
	.carousel-inner .prev		 { left: -50%; }
	.active > div { display:none; }
	.active > div:first-child { display:block; }
	.active > div:first-child + div { display:block; }
}
@media (min-width: 992px ) {
	.carousel-inner .active.left { left: -25%; }
	.carousel-inner .next        { left:  25%; }
	.carousel-inner .prev		 { left: -25%; }	
}
.revealOnScroll {
    opacity: 0!important;
}
.revealOnScroll.animated{
    opacity: 1!important;
}
.img-margin{
    margin: 0.1em;
}
.separacao-cor {
    padding: 20px;
    padding-top: 6vh;
    padding-bottom: 6vh;
    color: #fff;
    background: #e8b880;
    background: -moz-linear-gradient(-45deg,#e8b880 0,#7FD957 100%);
    background: -webkit-gradient(left top,right bottom,color-stop(0,#e8b880),color-stop(100%,#7FD957));
    background: -webkit-linear-gradient(-45deg,#e8b880 0,#7FD957 100%);
    background: -o-linear-gradient(-45deg,#e8b880 0,#7FD957 100%);
    background: -ms-linear-gradient(-45deg,#e8b880 0,#7FD957 100%);
    background: linear-gradient(135deg,#e8b880 0,#7FD957 100%);
}
.separacao-transparente {
    padding: 20px;
    padding-top: 6vh;
    padding-bottom: 6vh;
    color: #333;
    background: transparent;
}
.home-gradiend-tela {
    background: rgba(214, 178, 166,.8);
    background: -moz-linear-gradient(-45deg,rgba(214, 178, 166,.8),rgba(180, 98, 60,.8) 100%);
    background: -webkit-gradient(left top,right bottom,color-stop(0,rgba(214, 178, 166,.8)),color-stop(100%,rgba(180, 98, 60,.8)));
    background: -webkit-linear-gradient(-45deg,rgba(214, 178, 166,.8) 0,rgba(180, 98, 60,.8) 100%);
    background: -o-linear-gradient(-45deg,rgba(214, 178, 166,.8) 0,rgba(180, 98, 60,.8) 100%);
    background: -ms-linear-gradient(-45deg,rgba(214, 178, 166,.8) 0,rgba(180, 98, 60,.8) 100%);
    background: linear-gradient(135deg,rgba(214, 178, 166,.8) 0,rgba(180, 98, 60,.8) 100%);
    width: 100vw;
    height: 100vh;
}
.home-img-tela {
    color: #fff;
    /* background: url('/assets/img/bg-home.png') no-repeat fixed; */
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.border-none{
    border: none!important;
}
.thumbnail .fechado{
    opacity: 0.3;
}
.thumbnail .caption p{
    overflow-y: auto!important;
    height: 26vh!important;
}
.ano>hr{
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: #428bca solid 3px;
    z-index: -1;
}
.ano:after {
    background-color: #fff;
    border: 5px solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(153,153,153,0.45);
    box-shadow: 0px 0px 15px 5px rgba(153,153,153,0.45);
    background-image: url('/assets/img/planet.png');
    background-size: cover;
    background-position: center;
    clear: both;
    content: '';
    display: block;
    height: 40px;
    margin: 16px auto 0;
    width: 40px;

}
.img-cliente{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    width: 13vw;
    /* height: 10vh; */
    object-position: center;
    object-fit: cover;
    transition: 1s;
}
.img-cliente:hover,
.img-cliente:focus{
    -webkit-filter: none;
    filter: none;
    transition: 1s;
}
.black .left.carousel-control {
    margin-left: -3.5vw;
}
.black .right.carousel-control {
    margin-right: -3.5vw;
}
.black .carousel-control a {
    background: transparent;
    background-image: none!important;
    color: #000;
}
.black .carousel-control a:hover,
.black .carousel-control a:active,
.black .carousel-control a:focus {
    color: #444;
}
.black .carousel-indicators>li.active {
    background-color: #333;
}
.black .carousel-indicators>li {
    border-color: #333;
}
.rocket{
    width: 30px;
    height: 30px;
    object-position: center;
    object-fit: cover;
}
.pageDown{
    margin: 0;
    margin-top: 2vh;
    padding: 0;
    border: none;
    background: transparent;
    font-size: 5vh;
    font-weight: bold;
}
.text-limit{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-limit.vw26{
    max-width: 26vw;
}
.text-limit.c15{
    max-width: 15ch;
}
.btn-add-down, .notificacao {
    right: 0;
    position: fixed;
    overflow: hidden;
}
.btn-add-down, .galeria-img>img, .link-branco, .menu-backdrop, a {
    cursor: pointer;
}
.btn {
    border-color: transparent!important;
    margin-left: 2px;
    margin-right: 2px;
}
.btn i {
    padding-left: 10px;
    padding-right: 10px;
}
.btn.btn-quadrado {
    width: 8em;
    height: 7em;
    margin-bottom: 10px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-grid;
    align-content: center;
}
.contato .btn.btn-quadrado {
    width: 6.3em;
    height: 5.3em;
    margin-bottom: 5px;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-grid;
    align-content: center;
}
.btn-circle {
    border-radius: 50%!important;
    padding-left: 11.5px!important;
    padding-bottom: 7px!important;
}
.btn-add-down {
    width: 56px;
    height: 56px;
    padding: 0;
    color: #fff;
    box-shadow: rgba(0, 0, 0, .14) 0 2px 2px 0, rgba(0, 0, 0, .12) 0 3px 1px -2px, rgba(0, 0, 0, .2) 0 1px 5px 0;
    background-color: #3855ac;
    bottom: 0;
    z-index: 10;
    line-height: 70px;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
    letter-spacing: .5px;
    outline: 0;
    border: none;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-right: 20px;
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
}
.btn-add-down:active, .btn-add-down:focus, .btn-add-down:hover {
    color: #fff;
    background-color: #3855ac;
    transition: .5s;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
.sumir {
    opacity: 0;
}
.bg-default{
    color: #333;
    background-color: #ccc;
}
.notificacao {
    z-index: 101;
    top: 40px;
    left: 0;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .5);
}
.center, .center-img, .texto-grafico {
    text-align: center;
}
.notificacao .titulo{
    color: inherit;
}
.notificacao .icon {
    color: inherit;
    justify-content: flex-start;
    text-align: center;
    margin-bottom: 5px;
}
.notificacao .icon img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 100%
}
.notificacao .icon i {
    color: inherit;
}
.notificacao .titulo {
    font-weight: 700;
    font-size: 20px;
    border: none;
    margin: 0;
}
.texto-grafico, .texto-indicador {
    font-size: 20px;
    font-weight: 700;
}
.navbar-espaco-top {
    margin-top: 13vh;
}
.navbar-espaco-bottom {
    margin-top: 25vh;
}
.espaco-top {
    margin-top: 15vh;
}
.espaco-bottom{
    margin-bottom: 50px;
}
.center {
    justify-content: center;
    align-items: center;
}
.center-img {
    justify-content: center;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%}
.btn-default {
    color: #4d4d4d!important;
    background-color: #dadada!important;
}
.btn-default:hover {
    color: #4d4d4d!important;
    background-color: #c9c7c6!important;
}
.btn-laranja, .btn-laranja:hover, .btn-laranja, .btn-laranja:hover {
    color: #fff!important;
}
.btn-laranja {
    background-color: #7FD957!important;
    transition: 1s;
}
.btn-laranja:hover {
    background-color: #e8b880!important;
    transition: 1s;
}
.link-branco {
    color: #fff;
}
.link-branco:focus, .link-branco:hover {
    color: #eee;
}
.navbar-brand {
    margin-top: 6px;
    padding: 5px;
}
.navbar-top {
    color: #fff;
    z-index: 1!important;
}
.navbar-gradient a,
.btn-vipmodels-a {
    background-color: transparent;
    border-radius: 7px;
    color: #333;
}
.navbar-gradient a:hover,
.btn-vipmodels-a:hover {
    border-radius: 7px;
    background-color: rgba(127, 217, 87, .2)!important;
    transition: 1s;
}
.navbar-gradient a:active, .navbar-toggle:active,
.btn-vipmodels-a:active {
    border-radius: 50px;
    box-shadow: 0 4px 10px 0 rgba(127, 217, 87, .25), 0 6px 10px 0 rgba(127, 217, 87, .1);
    transition: .5s;
}
.navbar-gradient a:focus {
    background-color: transparent!important;
}
.navbar-gradient a:active,
.btn-vipmodels-a:active {
    background-color: rgba(127, 217, 87, .4)!important;
    transition: .5s;
}
.navbar-toggle {
    background-color: transparent!important;
    transition: 1s;
}
.navbar-toggle:active {
    background-color: rgba(127, 217, 87, .4)!important;
    transition: .15s;
}
.navbar-toggle .icon-bar {
    background-color: #fff;
}
::-moz-selection {
    color: #fff;
    background: #7FD957;
}
::selection {
    color: #fff;
    background: #7FD957;
}
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-thumb {
    background: #7FD957;
}
::-webkit-scrollbar-track {
    background: #ccc;
}
.texto-grafico {
    color: #565454;
}
.select2-container--default .select2-search--dropdown .select2-search__field, .select2-dropdown {
    border: 0 solid #000!important;
}
.select2-container--default .select2-selection--single {
    z-index: 0!important;
    height: 50px!important;
    color: #444!important;
    font-weight: 700!important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 45px!important;
}
.select2-dropdown {
    background-color: #fff!important;
    border: 0.5px solid #3855ac!important;
    box-shadow: inset 0 0px 0px rgba(0,0,0,0), 0 0 8px rgba(0,0,0,0)!important;
    transition: 0.5s;
}
.select2-selection__rendered {
    padding-top: 10px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: #444;
    background-color: rgba(0,0,0,0.1);
    transition: 0.5s;
}
.select2-container .select2-search--inline .select2-search__field{
    padding-top: 10px;
    padding-bottom: 10px;
}
.form-control, .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 0.5px solid #3855ac;
    box-shadow: inset 0 0px 0px rgba(0,0,0,0), 0 0 8px rgba(0,0,0,0);
    text-align: left!important;
}
.magin-left-5 {
    margin-left: 5px;
}
.form-initial {
    display: initial!important;
    width: auto!important;
}
:focus {
    outline: -webkit-focus-ring-color auto 0!important;
}
input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
    transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}
.icon-right-link:before {
    content: "\f054"!important;
}
.delay-1 {
    animation-delay: 40ms;
}
.delay-2 {
    animation-delay: 80ms;
}
.delay-3 {
    animation-delay: .12s;
}
.delay-4 {
    animation-delay: .16s;
}
.delay-5 {
    animation-delay: .2s;
}
.delay-6 {
    animation-delay: .24s;
}
.delay-7 {
    animation-delay: .28s;
}
.delay-8 {
    animation-delay: .32s;
}
.delay-9 {
    animation-delay: .36s;
}
.delay-10 {
    animation-delay: .4s;
}
.delay-11 {
    animation-delay: .44s;
}
.delay-12 {
    animation-delay: .48s;
}
.delay-13 {
    animation-delay: .52s;
}
.delay-14 {
    animation-delay: .56s;
}
.delay-15 {
    animation-delay: .6s;
}
.delay-16 {
    animation-delay: .64s;
}
.titulo-404 {
    color: #fff;
    font-size: 100px;
    font-weight: 700;
    border-bottom: 0 solid #fff;
}
.texto-404 {
    color: #fff;
    font-size: 25px;
}
.menu-sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 902;
    top: 0;
    right: 0;
    background-color: #EBEBEB;
    overflow-x: hidden;
    transition: .3s;
    padding-top: 60px;
}
.menu-sidenav a {
    padding: 10px 10px 10px 30px;
    text-decoration: none;
    font-size: 20px;
    color: #565454;
    display: block;
    transition: .4s;
}
.menu-sidenav a:hover {
    background-color: rgba(86, 84, 84, .2);
    border-radius: 20px;
    transition: .4s;
}
.menu-sidenav .closebtn {
    color: #565454;
    position: absolute;
    bottom: 0;
    left: -45px;
    font-size: 25px;
    margin-left: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.menu-sidenav .closebtn:hover {
    background-color: rgba(86, 84, 84, .2);
    border-radius: 20px;
    transition: .4s;
}
.menu-sidenav span {
    position: absolute;
    left: 30%}
.menu-sidenav i, .menu-sidenav span {
    opacity: 0;
    transition: .1s;
}
.menu-sidenav-open i, .menu-sidenav-open span {
    opacity: 1;
    transition: .1s;
}
.menu-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    background-color: rgba(0, 0, 0, .74);
}
.menu-sidenav .menu-foto {
    max-width: 250px;
    margin-top: -60px;
    margin-bottom: 10px;
}
nav.navbar{
    font-weight: bold;
    padding: 5px 0;
    color: #333;
    background-color: #EBEBEB;
}
.navbar-header{
    max-height: 72px;
}
.navbar-pageName{
    font-size: 26px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}
.navbar-toggle-left {
    float: left;
}
.navbar-right li {
    margin-right: 0;
    padding: 0;
}
.navbar-right li.separar{
    margin-left: 15px;
    margin-right: 16px;
}
.navbar-right {
    padding: 10px 0;
}
.navbar-brand {
    max-width: 65px;
}
#navbar.navbar-collapse {
    padding-right: 0!important;
    padding-left: 0!important;
}
.navbar-collapse li {
    font-size: 1.1em;
    display: inline-block;
}
.logo-pc {
    max-width: 200px;
}
.navbar-nav {
    margin: 0;
}
.nav {
    padding-left: inherit;
}
.nav>li>a{
    padding: 10px 10px;
}
.nav>li>a>p{
    margin: 0px;
}
.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #7FD957;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #b3b3b3;
    text-align: center;
}
.form-login, .resposta {
    color: #fff;
}
.resposta {
    margin-top: 5%}
.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .checkbox, .form-signin .form-signin-heading {
    margin-bottom: 10px;
}
.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type=email] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type=password] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.modal-login, .responsive-size {
    top: 50%;
    margin: auto;
    position: absolute;
}
.modal-login {
    z-index: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0);
    box-shadow: 5px 0 20px -5px transparent;
    border-radius: 3%;
    padding: 40px 50px 50px;
}
.responsive-size {
    z-index: 1;
    bottom: 5%;
    left: 5%;
    right: 5%;
    transform: translateY(-80%);
}
.btn-login {
    color: #fff;
    font-weight: bold;
    background-color: rgba(0, 0, 0, .4)!important;
    margin-left: 0;
    margin-right: 0;
    border: 0;
    border-radius: 20px;
}
.btn-login:hover {
    background-color: rgba(0, 0, 0, .2)!important;
    color: #fff!important;
}
::-webkit-input-placeholder {
    color: #444;
}
::-moz-placeholder {
    color: #444;
}
:-ms-input-placeholder {
    color: #444;
}
:-moz-placeholder {
    color: #444;
}
.reslogin {
    font-size: 12px;
}
.form-control {
    z-index: 0!important;
    margin-bottom: 10px!important;
    height: 50px!important;
    font-weight: 700!important;
    padding: 5px!important;
    background-color: #fff;
    border: 0.5px solid #3855ac;
    box-shadow: inset 0 0px 0px rgba(0,0,0,0), 0 0 8px rgba(0,0,0,0);
}
.form-control[type="file"]{
    border:none;
}
#total, .titulo {
    font-weight: 700;
}
h1 {
    font-size: 25px;
}
#total {
    margin: 2px 2px 2px 35%;
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
}
.panel-indicador {
    border-color: transparent;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 13px;
    box-shadow: 5px 0 20px -5px rgba(0, 0, 0, .3);
}
.panel-indicador .panel-titulo h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
}
.panel-indicador .panel-heading {
    color: #fb7343;
    background-color: #fff;
    border-color: transparent;
}
.panel-indicador .panel-icon, .panel-indicador .panel-grafico {
    color: #fff;
    box-shadow: 5px 0 20px -5px rgba(129, 55, 6, .4);
    border-radius: 10px;
}
.panel-indicador .panel-grafico {
    background-color: #FFE3D9;
    border-color: #FFE3D9;
    position: relative;
    top: -25px;
}
.panel-indicador .panel-titulo h2{
    margin-top: 10px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.panel-indicador .panel-titulo hr{
    margin-top: 5px;
    margin-bottom: 5px;
}
.panel-indicador p,
.panel-indicador h1,
.panel-indicador h2,
.panel-indicador h3,
.panel-indicador h4,
.panel-indicador h5,
.panel-indicador .texto-indicador{
    color: #333;
}
.panel-indicador a:focus, .panel-indicador a:hover {
    color: #23527c;
    text-decoration: none;
}
.panel-indicador .descricao{
    overflow-wrap: break-word;
}
.panel-indicador .descricao p{
    margin: 0px;
}
.panel-baterPonto-horarios{
    text-align: center;
}
.center-vertical {
    width: 100%; 
    height: 100%; 
    position: relative;
    display: table; 
 }
 .center-vertical span {
    /* position: absolute;  */
    top: 50%; 
    display: table-cell; 
    vertical-align: middle;
 }
 .center-vertical h1 {
    display:block; 
    /* position: relative;  */
    top: -50%;
 }
.text-horario>div:nth-child(1){
    font-weight: bold;
    font-size: 20px;
}
.text-horario>div:nth-child(2){
    font-variant: small-caps;
}
.panel-button{
    cursor: pointer;
}
.panel-indicador .panel-heading {
    color: #252c52;
    background-color: #fff;
    border-color: transparent;
}
.panel-indicador .panel-body{
    padding-top: 5px;
}
.panel-indicador .panel-icon,
.panel-indicador .panel-grafico {
    color: #fff;
    background-color: #3855ac;
    border-color: transparent;
    box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    transition: 0.5s;
}
.panel-button:hover.panel-indicador .panel-icon,
.panel-button:hover.panel-indicador .panel-grafico {
    color: #fff;
    background-color: #1f2c52;
    border-color: transparent;
    transition: 0.5s;
}
.panel-indicador .panel-icon {
    padding: 10px;
    position: absolute;
    left: -30px;
}
.panel-indicador .panel-grafico {
    position: relative;
    top: -25px;
}
.panel-grafico {
    overflow: hidden;
    max-width: 100%}
.panel-grafico>div {
    padding: 10px;
}
.grafico-legenda-remove-margin {
    margin: 8px -23% 17px -20%;
}
.grafico-legenda {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translateY(-50%);
    margin: auto;
}
.grafico-legenda .grafico-quadrado {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    text-align: center;
}
.grafico-legenda .grafico-quadrado.cor1 {
    background-color: #F15A24;
}
.grafico-legenda .grafico-quadrado.cor2 {
    background-color: #FF8C64;
}
.grafico-legenda .grafico-quadrado.cor3 {
    background-color: #FEA382;
}
.grafico-legenda span {
    display: inline-flex;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
    font-size: 17px;
    margin-left: 5px;
    margin-top: 5px;
}
.col-notific{
    margin: 0px;
    padding: 0px;
}
.detalhes-info-titulo{
    width: 100%;
    padding: 10px;
    background-color: #3855ac;
    overflow: hidden;
}
.detalhes-info-titulo span{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
}
.detalhes-info-titulo span.corta{
    display: inline-block;
    width: 30%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.detalhes-info-titulo span.pull-right{
    text-align: right;
}
.detalhes-info-corpo{
    padding: 10px;
}
.detalhes-info-corpo hr{
    border-top: 1px solid #7FD957;
}
.notificacao-img>img{
    width: 100%;
}
.img-avatar{
    max-width: 32px;
    max-height: 32px;
    object-fit: cover;
    border-radius: 100%;
}
.img-avatar-2x{
    max-width: 52px;
    max-height: 52px;
    object-fit: cover;
    border-radius: 100%;
}
.img-avatar-3x{
    max-width: 72px;
    max-height: 72px;
    object-fit: cover;
    border-radius: 100%;
}
/* img quebrada */
img {
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;  
  text-align: center;
  
  /* width: 100%; */
  height: auto;
  display: block;
  position: relative;
  min-height: 50px;
}
/*
:before pode ser cobrido pela imagem, mas quando a imagem está quebrada ele fica visível
*/
img:before { 
  content: " ";
  display: block;

  position: absolute;
  top: -10px;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(230, 230, 230);
  border: 2px dotted rgb(200, 200, 200);
  border-radius: 5px;
}
/*
:after mesmo comportamento de :before em relação à imagem
*/
img:after { 
  /* \f127 e a imagem de um link, do FontAwesome
     attr() pega um atributo do elemento, aqui pegando o "alt"
  */
  content: "\f8c4 " " '"  attr(alt) "' \f127";
  display: block;
  font-size: 16px;
  font-style: normal;
  font-family: "Font Awesome 5 Pro";
  line-height: normal;
  color: rgb(100, 100, 100);
  
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  text-align: center;
}
.galeria-img>img {
    display: inline-block;
    margin: 5px;
    object-fit: cover;
    width: 100px!important;
    height: 100px!important;
}
.page-galeria-img>img {
    display: inline-block;
    margin: 5px;
    object-fit: cover;
    width: 60px!important;
    height: 60px!important;
}
.titulo {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #000;
}
.search {
    z-index: 0;
    margin-left: center;
}
.sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}
.navbar-fixed-top {
    border: none!important;
}
.sidebar {
    display: none;
}
.nav-sidebar {
    margin-right: -21px;
    margin-bottom: 20px;
    margin-left: -20px;
}
.nav-sidebar>li>a {
    padding-right: 20px;
    padding-left: 20px;
}
.nav-sidebar>.active>a, .nav-sidebar>.active>a:focus, .nav-sidebar>.active>a:hover {
    color: #fff;
    background-color: #428bca;
}
.main {
    padding: 20px;
}
.main .page-header {
    margin-top: 0;
}
.placeholders {
    margin-bottom: 30px;
    text-align: center;
}
.placeholders h4 {
    margin-bottom: 0;
}
.placeholder {
    margin-bottom: 20px;
}
.placeholder img {
    display: inline-block;
    border-radius: 50%
}
.espaco-6p{
    padding-top: 6%;
}
.espaco-60 {
    padding-top: 60px;
}
.espaco-panel-home {
    padding-top: 30px;
}
.espaco-navbar-top {
    padding-top: 90px;
}
.form-login {
    border: none !important;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, .4)!important;
    padding: 10px!important;
}
input.form-login:nth-child(3n) {
    margin-bottom: 0px !important;
}
.page-carregando{
    margin-top: 60px;
}
.page-error{
    margin-top: 60px;
}
.margin-left-5{
    margin-left: 5px;
}
.center-text{
    text-align: center;
}
.opc{
    display: inline-block;
    font-size: 26px;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.5s;
}
.opc:hover{
    color: rgba(0, 0, 0, .3)!important;
    transition: 0.5s;
}
.opc.laranja{
    color: #F15A24;
}
.opc.laranja{
    color: #3855ac;
}
.opc.vermelho{
    color: #d95450;
}
.opc.cinza{
    color: #565454;
}
.icon-pesquisa i{
    font-size: 25px;
}
.modal-remove-confirm .modal-content{
    background-color: #eaeaea;
    color: #565454;
}
.modal-remove-confirm .modal-header{
    border: none;
    text-align: center;
}
.modal-remove-confirm .modal-footer{
    border: none;
    justify-content: flex-start;
    text-align: center;
}

.tituloPaginaGrande{
    font-size: 6em;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    fill: none;
    stroke: #000;
    stroke-width: 4px;
    stroke-dashoffset: -100;
    stroke-dasharray: 900;
    stroke-linecap: butt;
    stroke-linejoin: round;
}

.form-fantasma{
    color: #000!important;
    background: rgba(255, 255, 255, 0.70)!important;
    border: 0.5px solid transparent!important;
    box-shadow: inset 0 0px 0px transparent, 0 0 8px transparent!important;
}

/* Tela de PC */
@media (min-width:768px) {
.notificacao .corpo {
    color: inherit;
    padding-left: 23px;
}
.navbar-brand {
    max-width: 90px;
}
.icon-pesquisa{
    display: inline-block;
    text-align: center;
    color: #fff;
    background-color: #3855ac;
    margin-left: -2px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 13px;
    padding-bottom: 11px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}
.navbar-collapse li i{
    margin-right: 10px;
}
.btn-block-mobile {
    display: initial;
    width: auto;
}
.btn-block-mobile+.btn-block-mobile {
    margin-top: 0px;
}
.remove-pc, [remove-pc] {
    display: none!important;
}
}

/* Tela de Mobile Teclado Ativo */
@media (max-width:767px) and (max-height:450px) {
    .remove-mobile-teclado, [remove-mobile-teclado] {
        display: none!important;
    }
    .navbar-collapse{
        display: none;
    }
    .navbar .navbar-header i{
        font-size: 1.6em;
    }
    .navbar .navbar-header h3{
        font-size: 1.3em;
    }
    .navbar .navbar-header img{
        z-index: 1;
    }
    .navbar .navbar-header .navbar-toggle.navbar-toggle-right{
        z-index: 2;
    }
}

/* Tela de Mobile JioPhone 2 Ativo */
@media (max-width:400px) and (max-height:400px) {
    .remove-mobile-jiophone, [remove-mobile-jiophone] {
        display: none!important;
    }
    .navbar .navbar-header img{
        margin-bottom: 0px;
        max-width: 75%;
        z-index: 1;
    }
    .navbar .navbar-header .navbar-toggle.navbar-toggle-right{
        z-index: 2;
    }
    .menu-sidenav.menu-sidenav-open{
        width: 210px!important;
        padding-top: 45px!important;
    }
    .menu-sidenav .closebtn{
        bottom: auto;
    }
}

/* Tela de Mobile */
@media (max-width:767px) {
.remove-mobile, [remove-mobile] {
    display: none!important;
}
.btn-block-mobile {
    display: block;
    width: 100%;
}
.tituloPaginaGrande{
    font-size: 2.5em;
}
.fb_dialog{
    margin-bottom: 50px!important;
}
.icon-pesquisa i{
    font-size: 18px;
}
.icon-pesquisa{
    display: inline-block;
    text-align: center;
    color: #fff;
    background-color: #3855ac;
    margin-left: -2px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 14px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}
.notificacao .titulo{
    text-align: center;
}
.notificacao .corpo{
    text-align: center;
}
.espaco-indicador-home{
    padding-top: 20px;
}
.home-gradiend-tela {
    padding: 0;
}
.navbar-right {
    padding: 0px;
}
.navbar-right li {
    margin: 2%;
}
.navbar-header img{
    position: absolute;
    bottom: 5%;
    left: 2%;
    right: 5%;
    transform: translateY(-30%);
}
.espaco-navbar-top {
    padding-top: 90px;
}
.btn-add-down {
    margin-bottom: 80px;
}
.img-cliente{
    width: 35vw;
    margin: 2vh;
    /* height: 5vh; */
}
.fa-mobile-4x {
    font-size: 3.5em!important;
}
.fa-mobile-3x {
    font-size: 3em!important;
}
.grafico-legenda {
    text-align: left;
    position: inherit;
    margin-top: 80px;
}
.grafico-legenda span {
    max-width: 230px;
}
.notificacao i {
    color: inherit;
    font-size: 2em;
}
.navbar-collapse li>a>img {
    width: 23px;
}
body {
    margin-bottom: 50px!important;
}
.navbar-collapse li {
    font-size: .7em;
}
.button, p {
    color: #565454;
}
.logo-mobile {
    margin-bottom: -5px;
    max-width: 250px;
    cursor: url(https://planetsweb.com.br/setores/fotos/geral/logo.cur) 4 12, auto!important;border: none!important;
}
.navbar-brand {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%}
#navbar {
    z-index: 90;
    position: fixed;
    bottom: -15px;
    width: 100%;
    background-color: #EBEBEB;
    border-top: 0 solid transparent;
    padding: 0px;
    font-weight: normal;
}
nav.navbar{
    padding: 0px;
}
}
@media (min-width:768px) {
.main {
    padding-right: 40px;
    padding-left: 40px;
}
.sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
}
}
@media screen and (max-height:450px) {
    .menu-sidenav {
    padding-top: 15px;
}
.menu-sidenav a {
    font-size: 18px;
}
}
@media (max-width:340px) {
    .navbar-brand {
    max-width: 35px;
}
}
@media (max-width:360px) {
    .navbar-collapse li {
    font-size: .55em;
}
}
.form-inline-block{
    display: inline-block;
}
.form-inline{
    display: inline;
}
.display-none{
    display: none!important;
}