a{
    color: #7FD957;
}

a:hover{
    color: #72c54f;
}

a i{
    color: #7FD957;
}

.panelnotafiscal .linkIconMargim i{
    margin-top: 10px;
}

.linkIconMargim{
    padding: 5px;
}

.text-color-principal {
    color: #7FD957;
}


.cursosLink{
    cursor: pointer;
}

.iconLikeLove {
    position: absolute;
    left: 42%;
    top: 36%;
    display: table-cell;
    vertical-align: middle;
}

.iconLikeLove i {
    color: #FFF;
    font-size: 7em;
}

.card-fundo-img {
    padding: 30px;
    border-radius: 10px;
    margin-top: 50px;
    background-color: #000;
    text-align: center;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-fundo-img p {
    color: #fff;
    font-weight: lighter;
}

.tela-home h3 {
    font-weight: 600;
}

.nav.nav-tabs{
    margin-bottom: 30px;
}

.form-busca-home {
    width: 100%;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff!important;
    outline: none;
    border: none!important;
    margin: auto;
    box-shadow: none!important;
    box-shadow: 0 10px 6px -6px #777!important;
    margin-top: -25px;
}

.form-busca-home, .form-busca-home:focus, .form-sexo-home, .form-sexo-home:focus {
    border-radius: 10px;
    box-shadow: 0 5px 10px 1px rgba(0, 0, 0, .2)!important;
    margin-left: 5px;
    width: 100%;
}

.form-busca-home::-webkit-input-placeholder, .form-busca-home::placeholder {
    padding-left: 5px;
    color: #333!important;
}

.placaMidiaComparacao {
    width: 100%;
    padding: 10%;
    background-image: url('/assets/img/placaMidiaComparacaoFundo.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    overflow: hidden;
}

.placaMidiaComparacao .textoPlaca {
    font-family: cursive;
    font-size: 2.5em;
    color: #000!important;
    opacity: 0.65;
}

.borrarFoto {
    filter: blur(3px);
}

.btn-styleblinging i {
    color: #fff;
}

.btn-vipmodels,
.btn-styleblinging {
    color: #fff;
    background: #7FD957;
    border: none;
    transition: 1s;
}

.btn-vipmodels:hover,
.btn-styleblinging:hover {
    opacity: 0.6;
    color: #fff;
    transition: 1s;
}

.btn-vipmodels:focus, .btn-vipmodels:active,
.btn-styleblinging:focus,.btn-styleblinging:active {
    color: #fff;
    background: #72c54f;
    transition: 0s;
}

.btn-next.btn-styleblinging:focus, .btn-next.btn-styleblinging:active{
    background: #7FD957;
    opacity: 1!important;
}

.btn-vipmodels-o,
.btn-styleblinging-o {
    color: #7FD957;
    background: #EBEBEB;
    border: 1px solid #7FD957!important;
    z-index: -2;
    transition: 1s;
}

.btn-vipmodels-o:hover,
.btn-styleblinging-o:hover {
    opacity: 0.6;
    color: #7FD957;
    transition: 1s;
}

.btn-vipmodels-o:focus, .btn-vipmodels-o:active,
.btn-styleblinging-o:focus, .btn-styleblinging-o:active {
    color: #7FD957;
    background: #dbdbdb;
    transition: 0s;
}

.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-results__option,
.select2-container--default .select2-selection--multiple .select2-selection__choice{
    font-size: 1.3em;
}

.form-control {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid #7FD957;
    box-shadow: none;
    font-weight: normal!important;
    font-size: 1.3em;
}

.form-control:focus {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid #7FD957;
    box-shadow: none;
    font-weight: normal!important;
}

.select2-container--default .select2-search--dropdown .select2-search__field, .select2-dropdown {
    border: 0 solid #000!important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default .select2-selection--multiple {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid #7FD957;
}

.select2-container--default .select2-selection--single {
    z-index: 0!important;
    height: 50px!important;
    color: #444!important;
    font-weight: normal!important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 45px!important;
}

.select2-dropdown {
    background-color: #fff!important;
    border: 0.5px solid #7FD957!important;
    transition: 0s;
}

.select2-selection__rendered {
    padding-top: 10px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: #444;
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0s;
}

.form-control, .select2-container--default .select2-selection--single {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid #7FD957;
    box-shadow: none;
}

.icon-circle {
    background-color: #fff;
    color: #fff;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 3px;
    text-align: center;
    border-radius: 50%;
}

.icon-circle.ic-2x {
    width: 35px;
    height: 35px;
    padding: 4px;
}

hr.hr-black {
    border-top: 1px solid #888;
}

.banner-foto-perfil {
    text-align: center;
}

.banner-foto-perfil .foto-principal {
    margin-top: -50px;
    display: inline-block;
    width: 150px;
    height: 150px;
    background-color: #fff;
    padding: 4px;
    z-index: 2;
    margin-left: 0;
    margin-right: 0;
}

.foto-principal .foto-btn-editar {
    float: right;
    margin-right: -10%;
    margin-top: -20%;
}

.foto-principal .foto-btn-editar i {
    color: #000;
    padding: 10px 5px 10px 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
}

.banner-foto-perfil .banner {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
}

#pagePerfil {
    margin-top: -5vh;
}

.icons-separacao {
    margin-bottom: 5px;
}

.infoServicos span {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;
}

.publicacao img:hover {
    filter: grayscale(0.7) blur(1px);
}

.publicacao:hover {
    filter: grayscale(0.7);
}

.publicacao:hover::after {
    font-family: 'Font Awesome\ 5 Pro'!important;
    content: '\f004\ ' attr(likes);
    font-size: 12px;
    max-width: 110px;
    white-space: pre;
    word-wrap: break-word;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.85);
    padding: 3px 7px;
    color: #FFF;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    margin-top: -22px;
    z-index: 1000;
}

.publicacao {
    margin-bottom: 35px;
}

.publicacao:hover::before {
    content: attr(descricao);
    font-size: 10px;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.85);
    padding: 3px 7px;
    color: #FFF;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    z-index: 1000;
}

.publicacao img {
    width: 80%;
    height: 400px;
    object-fit: cover;
    object-position: center;
}

.certificados .label {
    font-size: 15px;
    margin-right: 10px;
}

.certificados {
    margin-bottom: 20px;
}

.background-color-vipmodels, .color-bg-vipmodels {
    background: #7FD957;
    background: linear-gradient(120deg, #7FD957 0%, #7FD957 100%);
}

.color-vipmodels {
    color: #7FD957;
}

.panel-user-previa {
    border: none;
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, .4);
    transition: 0.5s;
}

.panel-user-previa span, .panel-user-previa p {
    font-size: 10pt;
}

.panel-user-previa h1,
.panel-user-previa h2,
.panel-user-previa h3,
.panel-user-previa h4,
.panel-user-previa h5,
.panel-user-previa h6 {
    margin: 0px;
    margin-bottom: 5px;
}

.panel-user-previa a {
    color: #444;
}

.panel-user-previa.link:hover {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, .8);
    transition: 0.5s;
}

.panel-user-previa .descricaoCurta {
    max-width: 95%;
}

.form-inline-auto{
    display: inline-block;
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
}

.imgTabelaItens img{
    height: 150px;
    object-fit: cover;
}

.panel-user-previa .foto-principal {
    object-fit: cover;
    object-position: center;
    width: 150px!important;
    height: 150px!important;
}

.top.panel-user-previa a {
    text-decoration: none!important;
}

.top.panel-user-previa .panel-body {
    padding-top: 5px;
}

.top.panel-user-previa .foto-perfil {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
}

.top.panel-user-previa .foto-perfil .foto-principal {
    width: 100%!important;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
}

.top.panel-user-previa .tag-top-foto {
    position: absolute;
    width: 50px;
    height: 50px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 40px;
    padding-top: 9px;
    font-size: 18px;
    font-weight: bold;
    z-index: 99;
    color: #fff;
    background-color: #7FD957;
}

.panel-notificacao {
    border: none;
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, .4);
    transition: 0.5s;
}

.panel-notificacao:hover {
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, .8);
    transition: 0.5s;
}

.panel-notificacao.nao-visualizada {
    box-shadow: 0px 0px 20px 0px rgba(207, 153, 89, .4);
}

.panel-notificacao.nao-visualizada::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    float: right;
    margin-top: 15px;
    margin-right: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #7FD957;
}

.panel-notificacao h2 {
    font-size: 24px;
    margin: 0px;
    margin-bottom: 5px;
}

.panel-notificacao a {
    color: #444;
}

.panel-notificacao .descricaoCurta {
    max-height: 40px;
    margin-bottom: 0px;
}

.panel-notificacao .data {
    margin-top: 0px;
    font-size: 12pt;
}

.panel-notificacao.nao-visualizada .data {
    color: #7FD957;
}

.foto-principal img.img-circle {
    width: 155px;
    height: 150px;
    object-fit: cover;
}

.foto-principal img.img-circle:before {
    border-radius: 100%;
    top: -5px;
    left: -5px;
    width: 150px;
    height: 150px;
}

.foto-principal img.img-circle:after {
    top: 40%;
}

.col-padding-5 {
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.home-gradiend-tela {
    background: rgba(0, 0, 0, 0.85);
}

.home-banner {
    width: 100%;
    height: 100%;
    background-image: url('/assets/img/bannerPaginaInicial.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.home-img-tela {
    height: 31vw;
}

.home-img-tela .conteudo {
    padding-top: 13vw;
}

.btn-home {
    box-shadow: 0 5px 10px 1px rgba(0, 0, 0, .2);
}

.banner-home-descricao {
    color: #fff!important;
    text-align: center;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 1.5rem;
}

.banner-home-titulo {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 3.5rem;
    color: #fff;
    font-weight: 900!important;
}

.btn-ver-ocultar-senha {
    width: 100%;
    overflow: hidden;
}


.dataTables_wrapper th {
    cursor: pointer;
}

.dataTables_wrapper .dataTables_filter label {
    font-size: 0px;
    width: 80%;
}

.dataTables_wrapper .icon-pesquisa {
    background-color: transparent;
    color: #7FD957;
    padding-bottom: 16px;
    border-radius: 0px;
    border-bottom: 0.5px solid #7FD957;
}

.dataTables_wrapper .dataTables_filter label input {
    width: 80%;
}

.col-xs-6 .btn.btn-quadrado{
    align-content: initial;
}

.como-funciona h3{
    font-size: 3.1rem;
    font-weight: bolder;
    text-align: center;
}

.como-funciona .panel{
    border: none!important;
    border-radius: 6px;
    box-shadow: rgba(29,29,31,.06) 0 6px 25px, rgba(29,29,31,.08) 0 0 2px;
}

.como-funciona .panel .linha-passos{
    color: #d39d5d;
    width: 40%;
    margin-top: 10px;
    text-align: center;
    height: 2px;
}

.tutorial-carousel .carousel-indicators{
    position: relative!important;
}

.tutorial-carousel .carousel-indicators .active{
    background-color: #7FD957;
}

.tutorial-carousel .carousel-indicators li{
    border: 1px solid #7FD957;
}

[aling="left"]{
    text-align: left;
}

[aling="right"]{
    text-align: right;
}

[aling="center"]{
    text-align: center;
}

.panelProduto .preco{
    font-size: 18px;
}

.panelProduto .labelEstoqueProduto{
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.panelProduto .imgProduto,
.imgProduto{
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.panelPedido .numero{
    font-size: 1.7em;
}

.panelPedido .integracao{
    font-size: 1em;
}

.panelPedido .data{
    font-size: 0.8em;
}

select.situacao:focus{
    color: #000!important;
}

.filtros .tags-filtro{
    font-size: 1.2em;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.filtros{
    margin-bottom: 30px;
}

.navbar-right li.destaque{
    background-color: #7FD957;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px 0.5px #7FD957; */
    transform: translateY(-35%);
    box-shadow: 0 5px 10px 1px rgba(0127, 217, 87, .3);
}
.navbar-right li.destaque a{
    transform: translateY(4%);
    color: #fff;
}
.navbar-right li.destaque i,
.navbar-right li.destaque p{
    color: #fff;
}

.input-mes-dados{
    border: none;
    background: transparent;
    border-bottom: 0.5px solid #7FD957;
}

/* Tela de Mobile */

@media (max-width:767px) {
    .panelPedido .numero{
        font-size: 1.5em;
    }

    .imgTabelaItens{
        width: 100px;
    }

    .panelPedido .integracao{
        font-size: 1em;
    }

    .panelPedido .data{
        font-size: 0.8em;
    }
    .dataTables_wrapper .icon-pesquisa {
        padding-bottom: 18px;
    }
    .dataTables_wrapper .dataTables_filter label input {
        display: inline-block;
        width: 60%;
        margin-right: 5px;
    }
    .publicacao {
        margin-bottom: 20px;
    }
    .publicacao img {
        width: 95%;
        height: 350px;
    }
    img.foto-principal:before {
        padding-bottom: 90px;
    }
    label.control-label {
        width: 100%;
        text-align: left;
    }
    .panel-user-previa .foto-principal {
        width: 100%!important;
        height: 150px!important;
        border-radius: 5px;
        border-bottom-left-radius: 30px;
    }
    .placaMidiaComparacao {
        padding: 25%;
    }
    .placaMidiaComparacao .textoPlaca {
        font-size: 2em;
    }
    .nav>li p {
        font-size: 11px;
        margin-bottom: 10px!important;
    }
    .nav>li {
        margin: 1%;
    }
    .home-img-tela {
        height: 50vh;
    }
    .home-img-tela .conteudo {
        padding-top: 12vh;
    }
    .banner-home-titulo {
        font-size: 4rem;
    }
    .banner-home-descricao {
        font-size: 1.5rem;
    }
    .form-busca-home {
        margin-top: -15px;
    }
}