@import url("./assets/fw/fontawesome-pro-5.12.0-web/css/all.min.css");
@import url("./assets/fw/animate.css/animate.css");
@import url("../node_modules/select2/dist/css/select2.min.css");
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("./assets/css/tema.css");
@import url("./assets/css/styles.css");

body {
    margin: 0;
    background-color: #f1f1f1;
}
.nav>li {
    display: inline-block;
}